/* You can add global styles to this file, and also import other style files */
//$icon-font-path: '../node_modules/open-iconic/font/fonts/';
//$fa-font-path: '../node_modules/font-awesome/fonts/';
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");
@import '~open-iconic/font/css/open-iconic-bootstrap';
@import './style/variables';
@import '~font-awesome/scss/font-awesome';
@import '~bootstrap/scss/bootstrap';
@import '~angular-notifier/styles.scss';
@import './style/daikin-icon-style';
@import '~dragula/dist/dragula.css';
@import "~nouislider/distribute/nouislider.min.css";

.wrapper {
  min-height: 100vh;
  overflow: auto;
}

header {
  padding-bottom: 45px;
  padding-top: 45px;
}

h1 {
  &.page-header {
    font-size: 2.25em;
    color: $black;
    background-color: $header-grey;
    padding-left: 0.5em;
    padding-top: 0.15em;
    padding-bottom: 0.25em;

    span {
      font-size: 0.5em;
      vertical-align: middle;
      font-weight: 100;
    }

    .btn {
      margin-left: 0.25em;
      margin-right: 0.25em;
    }
  }
}

h2 {
  &.welcome-text {
    color: $black;
    padding-top: 1.5em;
  }
}

.daikin-logo {
  height: 32px;
}

.input-group-text {
  color: $brand-primary;
}

.half-fluid-button {
  width: 46%;
}

.ng-dirty.ng-invalid:not(form),
.ng-touched.ng-invalid:not(form) {
  @extend .is-invalid
}

.invalid-feedback {
  display: inline-block;
}

.border-bottom {
  border-bottom: 1px solid $gray-dark;
}

/* Saving button animation */
$animation-speed: 1000ms;
$dot-size: 10px;

@mixin animation($delay: 0ms) {
  animation: fx $animation-speed ease infinite $delay;
}

.animation-container {

  display: inline-block;

  .dot {
    width: $dot-size;
    height: $dot-size;
    border: ($dot-size / 5) solid white;
    border-radius: 50%;
    float: left;
    margin: 0 ($dot-size / 2);
    transform: scale(0);
    @include animation();

    &:nth-child(2) {
      @include animation($animation-speed * 0.3);
    }

    &:nth-child(3) {
      @include animation($animation-speed * 0.6);
    }
  }

  @keyframes fx {
    50% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

/* End saving button animation */

/* Start multi select component */
ss-multiselect-dropdown {
  & .dropdown-item.active,
  & .dropdown-item:active {
    background-color: $white;
    color: $black;
  }
}

/* End multi select component */

.table-link {
  tbody {
    tr:hover {
      cursor: pointer;
      color: $primary;
    }
  }
}

.btn-file {
  position: relative;
  overflow: hidden;
  margin: 0;
  width: 100%;
  height: 100%;

  input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
  }

  .image-placeholder {
    display: table;
    width: 100%;
    height: 100%;

    padding: 7px;
    font-size: 12px;
    color: $gray-light;
    background-color: #F9F9F9;

    span {
      display: table-cell;

      text-align: center;
      vertical-align: middle;

      font-weight: 100;
    }
  }
}

.image-edit {
  height: 100%;
}

.gu-mirror {
  border: solid 1px $brand-primary;

  .meta-property-move,
  .header-group-move {
    cursor: grabbing;
  }
}

.gu-transit {
  color: $brand-primary;
  opacity: 1;
  margin-left: 10px;
}

.meta-property-move,
.header-group-move {
  cursor: grab;
}

.side-menu-bg {
  background-color: $gray-dark;
}

aside {
  flex-grow: 0;
  flex-shrink: 0;
  min-height: 100vh;
  min-height: calc(100vh - 24px);

  &.opened {
    flex-basis: $opened-side-menu-width;

    .fa {
      font-size: 1.50em;
      padding-left: 0.5em;
      padding-right: 0.5em;
    }
  }

  .nav-item {
    padding-left: 4px;
    padding-right: 2px;
  }

  .nav-link {
    padding-left: 4px;
    padding-right: 2px;
  }

  &.closed {
    flex-basis: $closed-side-menu-width;

    .fa {
      font-size: 2.0em;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

main {
  flex-basis: 100%;
}

.product-card {
  margin-bottom: $grid-gutter-width;
}

.pointer {
  cursor: pointer;
}

.card-header,
.card-body,
.card-footer {
  padding-top: 0;
  padding-bottom: 0;
}

.card-header,
.card-footer {
  padding-left: 10px;
  padding-right: 10px;
  background-color: $white;
}

.card-footer {
  font-size: 0.9em;
  border-top: none;
}

.card-header {
  font-size: 1.5em;
  color: $primary;
  border-bottom: none;
}

.compare-image {
  width: 210px;
  height: 105px;
  border: solid #58585a 1px;
  border-radius: 6px;
}

.card-select-checkbox {
  display: inline-block;
  border: 1px $gray solid;
  border-radius: 3px;
  font-size: 0.7rem;
  padding: 0 0.1em 0.15em 0.1em;
  width: 1.2rem;
  height: 1.2rem;
  line-height: 1.2rem;
  cursor: pointer;
  vertical-align: text-top;
  color: $primary;
}

/* nouislider */
//.noUi-target {
//margin-right: -7px;
//}

.noUi-base,
.noUi-connects,
.noUi-target,
.noUi-connect {
  border-radius: 0;
  border: none;
  background-color: $primary;
  height: 2px;
}

.noUi-handle {
  width: 24px !important;
  height: 24px !important;
  border-radius: 50%;
  border-color: #E0E7ED !important;
  margin-top: -6px;

  &:before,
  &:after {
    display: none;
  }
}

.filter-changed,
.filter-changed:hover {
  font-weight: 600;
  background-color: orange;
}

.slider-filter-changed {
  font-weight: 600;

  .noUi-base,
  .noUi-connects,
  .noUi-target,
  .noUi-connect {
    background-color: orange;
  }

  .noUi-handle {
    background-color: orange;
    border-color: orange !important;

  }
}

//.noUi-handle-upper {
//  margin-right: 7px;
//  margin-left: 17px;
//}

.noUi-tooltip {
  font-size: 14px;
  border: none;
  background-color: $sidebar-grey;
  margin: 0;
  padding: 0;
}

.list-group-brand {
  font-size: 34px;
  display: inline-block;
  min-width: 25%;
  border-bottom: 2px solid $gray-light;
  margin-bottom: 0.25em;

  img {
    max-height: 40px;
  }
}

.versus-property-container {
  padding-bottom: 1.5em;
  border-bottom: 1px solid $gray-dark;
}

.versus-header-container {
  border-top: 5px solid;
}

.versus-small-header-container {
  color: $white;
  font-size: smaller;
}

.always-show-container {
  position: absolute;
  z-index: 999;
  border: 1px solid red;
}

.versus-yes {
  color: #26DE88;
}

.versus-no {
  color: #FF5631;
}

.versus-grey {
  color: $gray-disabled;
}

.versus-option {
  color: #F5D644;
}

.versus-select-table-header {
  text-align: center;
  color: $white;
}

.versus-select-table-row {
  text-align: center;
}

.versus-even {
  background-color: #FAFAFA;
}

.versus-odd {
  background-color: #F2F2F2;
}

.versus-matrix-table,
.versus-boolean-table {
  width: 100%;

  tbody {
    tr {
      border-top: 1px solid $header-grey;
      border-bottom: 1px solid $header-grey;
    }
  }
}

.versus-matrix-table {
  td:first-child {
    width: 150px;
  }
}

.versus-price {
  color: white;
  background-color: $gray-dark;
  border-radius: 20px;
  padding: 10px 20px;
  margin-top: -0.5em;
}

.versus-property-name {
  font-weight: bold;
}

.versus-property-description {
  font-size: smaller;
  color: $gray-light;
}

.btn-chart-type {
  margin: 5px;
  background-color: $header-grey;
  white-space: normal;

  &.selected {
    background-color: $primary;
    color: $white;
  }
}

.daikin-table,
.property-table {
  width: 100%;
  font-size: 0.9em;

  border: none;
  border-collapse: collapse;
  border-spacing: 2px;

  tr {
    &.invisible {
      height: 1px;
      border: none;
    }

    &.success {
      background-color: #26DE88;
    }

    &.failed {
      background-color: #d9534f;
    }
  }

  th, td {
    font-weight: 400;
    padding: 0.2em 0.4em 0.3em 0.4em;
  }

  td {
    color: $black;
    border: solid 1px $gray-lightest;
    vertical-align: middle;
  }

  .property-value {
    color: $gray-light;
  }

  .property-separator {
    width: 1px;
    border: none;
    padding: 0 0 0 1px;
  }

  .dragula-cell {
    width: 3px;

    border-left: none;
    border-top: none;
    border-bottom: none;
  }

  .actions-cell {
    width: 60px;
  }
}

.property-table {
  th, td {
    width: 50%;
  }
}

.property-image-container {
  font-size: 0.9em;
  font-weight: 400;

  .property-image-wrapper {
    border: dashed 1px $gray-light;
    margin-top: 5px;
    padding: 5px;

    .property-image-sizer {
      position: relative;
      overflow: hidden;
      padding-bottom: 50%;

      .property-image {
        position: absolute;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.fa-primary {
  color: $primary;
  font-size: 1.25em;
}

.btn-icon {
  font-size: 1.25em;
  height: 1.25em;
  margin: 0;
  padding: 0;

  & span {
    vertical-align: text-top;
  }
}

.text-light-grey {
  color: $gray-light;
}

.product-card-logo {
  max-height: 25px;
}

.no-text-decoration {
  &:hover {
    text-decoration: none;
    color: $primary;
  }
}

.product-brand-image-container {
  margin-bottom: -15px;

  img {
    height: 50px;
  }
}

.product-card-image {
  max-height: 150px;
}

dpc-select {
  &.ng-invalid.ng-dirty {
    button {
      border: solid 1px red;
    }
  }
}

.chart-container {
  width: 99%;
}

.heat-map-0 {
  background-color: #008837;
  color: $white;
}

.heat-map-1 {
  background-color: #4cab26;
  color: $white;
}

.heat-map-2 {
  background-color: #b2db10;
  color: $white;
}

.heat-map-3 {
  background-color: #fffe00;
  color: $black;
}

.heat-map-4 {
  background-color: #ffb200;
  color: $white;
}

.heat-map-5 {
  background-color: #ff4c00;
  color: $white;
}

.heat-map-6 {
  background-color: #ff0000;
  color: $white;
}

.heat-map-table {
  border: solid 1px $black;

  td {
    border: solid 1px $black;
  }

  td,
  th {
    padding: 2px 5px;
  }

  td:not(:first-child),
  th:not(:first-child) {
    text-align: center;
  }
}
