$gray-lightest: #ececec;
$gray: #87888a;
$gray-dark: #555555;
$header-grey: #E7E7E7;
$sidebar-grey: #F5F5F5;
$black: #000000;
$white: #ffffff;
$red: #a8252e;
$green: #5cb85c;
$primary: #0099cc;
$secondary: #008634;
$brand-primary: $primary;
$gray-light: #636c72;
$gray-disabled: #DFDFDF;

$closed-side-menu-width: 40px;
$opened-side-menu-width: 250px;

// font used in bootstrap
$font-family-sans-serif: "Open Sans", sans-serif;

// font color used in bootstrap
$body-color: $gray-dark;

$input-bg: #edf2f5;
$input-color: $black;
$input-border-color: $gray-lightest;
$input-color-placeholder: #c2c2c2;
$input-group-addon-bg: $white;

$custom-select-indicator-color: $gray-lightest;
$custom-select-bg: $gray-lightest;

$navbar-light-active-color: $brand-primary;
$navbar-light-color: $black;

$nav-tabs-active-link-hover-color: $black;
$nav-tabs-active-link-hover-bg: $gray-lightest;
$nav-tabs-border-radius: 0;
//$border-radius:          2.5rem;
//$border-radius-lg:       3rem;
//$border-radius-sm:       2rem;

$card-border-color: $header-grey;
