@import "daikin-icon-variables";

@font-face {
  font-family: 'daikin-icons';
  src:
    url('#{$icomoon-font-path}/daikin-icons.ttf?qhch66') format('truetype'),
    url('#{$icomoon-font-path}/daikin-icons.woff?qhch66') format('woff'),
    url('#{$icomoon-font-path}/daikin-icons.svg?qhch66#daikin-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="daikin-"], [class*=" daikin-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'daikin-icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.daikin-skier-icon {
  &:before {
    content: $daikin-skier-icon;
  }
}
.daikin-user-icon {
  &:before {
    content: $daikin-user-icon;
  }
}
.daikin-document-icon {
  &:before {
    content: $daikin-document-icon;
  }
}
.daikin-spinner-icon {
  &:before {
    content: $daikin-spinner-icon;
  }
}
.daikin-registration-icon {
  &:before {
    content: $daikin-registration-icon;
  }
}
.daikin-nyeremeny-icon {
  &:before {
    content: $daikin-nyeremeny-icon;
  }
}
.daikin-verszeny-szabalyzat-icon {
  &:before {
    content: $daikin-verszeny-szabalyzat-icon;
  }
}
.daikin-lock-icon {
  &:before {
    content: $daikin-lock-icon;
  }
}
.daikin-mail-icon {
  &:before {
    content: $daikin-mail-icon;
  }
}
.daikin-sales-down-icon {
  &:before {
    content: $daikin-sales-down-icon;
  }
}
.daikin-sales-up-icon {
  &:before {
    content: $daikin-sales-up-icon;
  }
}
.daikin-pipa-icon {
  &:before {
    content: $daikin-pipa-icon;
  }
}
.daikin-calendar-icon {
  &:before {
    content: $daikin-calendar-icon;
  }
}
.daikin-arrow-right-icon {
  &:before {
    content: $daikin-arrow-right-icon;
  }
}

